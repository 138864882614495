<template>
  <div class="box-wrap">
    <div class="top">
      <div class="header-box">
        <img class="logo" src="@/assets/img/hdetails/logo.png" alt="" />
        <img class="avt" src="@/assets/img/hdetails/avt.png" alt="" />
        <p>服务顾问</p>
        <h3>如山选墓 - 优选陵园墓地，保障品质服务</h3>
        <a class="tel-css" @click="telClick" href="tel:4008-122-133"
          ><span>4008-122-133</span
          ><img src="@/assets/img/hdetails/phone.png" alt=""
        /></a>
        <a class="h-btn" @click="telClick" href="tel:4008-122-133">立即咨询</a>
      </div>
      <div class="content-box" v-if="detail">
        <h3 class="c-title">{{ detail.title || "资讯标题" }}</h3>
        <div class="c-info">
          <p class="info-f">来自：如山选墓</p>
          <div class="info-b">
            <p>
              <img src="@/assets/img/homerec/zj.png" alt="" />{{
                detail.createTime | setTime
              }}
            </p>
            <img class="see-img" src="@/assets/img/homerec/see.png" alt="" />
            <span class="number-css">{{
              Number(detail.realReadAmount || 0) +
              Number(detail.baseReadAmount || 0)
            }}</span>
          </div>
          <div class="html-wrap">
            <div v-html="detail.content"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="btm-box">
      <div class="list-title">
        <div class="title-l">
          <img src="@/assets/img/homerec/title-icon.png" alt="" />
          <h3>推荐内容</h3>
        </div>
      </div>
      <div
        class="zx-item"
        @click="goDetail(zx)"
        v-for="zx of zxList"
        :key="zx.id"
      >
        <div class="zx-left">
          <h3 class="zx-title two-line">{{ zx.title }}</h3>
          <div class="zx-info">
            <p>
              <img src="@/assets/img/homerec/zj.png" alt="" />{{
                zx.createTime | setTime
              }}
            </p>
            <img class="see-img" src="@/assets/img/homerec/see.png" alt="" />
            <span class="number-css">{{
              Number(zx.realReadAmount || 0) + Number(zx.baseReadAmount || 0)
            }}</span>
          </div>
          <div class="zx-tag">
            <span
              v-for="(tag, tagIndex) of zx.categorys"
              :key="'tag_' + tagIndex"
            >
              {{ tag }}
            </span>
          </div>
        </div>
        <div class="zx-right">
          <img :src="zx.coverImages[0]" alt="" />
        </div>
      </div>
      <div @click="moreClickTwo" class="more-btn">查看更多></div>
    </div>
    <div class="kf-box">
      <div class="kf-btn" @click="goKf">在线咨询</div>
    </div>
    <div class="fixed-box">
      <div class="home-btn-css" @click="goHomeRec">
        <img src="@/assets/img/details/home-icon.png" alt="" />
        <span>首页</span>
      </div>
      <div class="btns">
        <a @click="telClick" href="tel:4008122133" class="fiexd-btn">
          <img src="@/assets/img/homerec/phone.png" alt="" />
          <div class="btn-item">
            <p>电话咨询</p>
            <span>4008 122 133</span>
          </div>
        </a>
        <div class="fiexd-btn" @click="showForm">立即预约看墓</div>
      </div>
    </div>
    <van-popup
      v-model="showModal"
      safe-area-inset-bottom
      position="bottom"
      closeable
    >
      <div class="form-wrap">
        <FormModal :startTime="startTime" />
      </div>
    </van-popup>
  </div>
</template>
<script>
import {
  getSeeDetalApi,
  getSeeListApi,
  collectPointApi,
  seekAdviceApi,
} from "@/api/apiList.js";
import { mapGetters, mapMutations } from "vuex";
import { setChannel } from "@/util/setChannel.js";
export default {
  name: "infoDetail",
  filters: {
    setTime(time) {
      let str = time.slice(0, 10);
      return str;
    },
  },
  components: {
    FormModal: () => import("@/views/homerec/form.vue"),
  },
  data() {
    return {
      detail: null,
      zxList: [],
      startTime: 0,
      showModal: false,
    };
  },
  activated() {
    this.init();
    setChannel(this);
  },
  computed: {
    ...mapGetters(["cityCode", "pages", "scanSeekIds", "UUID"]),
  },
  methods: {
    ...mapMutations(["setScanSeekIds"]),

    init() {
      let id = this.$route.query.id || "";
      if (!this.scanSeekIds.includes(id)) {
        this.setScanSeekIds(id);
        seekAdviceApi({ id });
      }
      this.startTime = +new Date();
      this.getSeeDetalFun(id);
      this.getSeeListFun(this.cityCode);
    },
    getSeeDetalFun(id) {
      getSeeDetalApi({ id }).then((res) => {
        this.detail = res.data.data;
        console.log(this.detail, "this.detail ");
      });
    },
    getSeeListFun(code) {
      getSeeListApi({
        cityCode: code,
        current: 1,
        size: 6,
        category: -1
      }).then((res) => {
        this.zxList = res.data.data?.records || [];
      });
    },
    moreClickTwo() {
      if (this.pages.channel && this.pages.subChannel) {
        this.$router.push(
          `/rec/infolist/${this.pages.channel}/${this.pages.subChannel}?citycode=${this.cityCode}`
        );
      } else {
        this.$router.push(`/rec/infolist?citycode=${this.cityCode}`);
      }
    },
    goHomeRec() {
      if (this.pages.channel && this.pages.subChannel) {
        this.$router.push(
          `/homerec/${this.pages.channel}/${this.pages.subChannel}`
        );
      } else {
        this.$router.push(`/homerec`);
      }
    },
    // 埋点
    buryingPage(type) {
      this.collectPointParams = {
        channelCode: this.pages.channel,
        subChannelCode: this.pages.subChannel,
        activityId: this.activityId,
        eventType: type,
        uuid: this.UUID,
        localUrl: location.href,
      };
      collectPointApi(this.collectPointParams)
        .then((res) => {
          console.log(res, "埋点成功");
        })
        .catch((err) => {
          console.log(err, "买点失败");
        });
    },
    telClick() {
      this.buryingPage(3);
    },
    showForm() {
      this.showModal = true;
    },
    goDetail(item) {
      if (item.id === this.$route.query.id) {
        return;
      }
      if (this.pages.channel && this.pages.subChannel) {
        this.$router.replace(
          `/rec/infodetail/${this.pages.channel}/${this.pages.subChannel}?id=${item.id}`
        );
      } else {
        this.$router.replace(`/rec/infodetail?id=${item.id}`);
      }
      this.init();
    },
    goKf() {
      this.buryingPage(2);
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfc51653415422b34fa";
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin flex {
  display: flex;
  align-items: center;
}
.box-wrap {
  background: #f7f7f7;
  padding-bottom: calc(1.26rem + env(safe-area-inset-bottom));
  .top {
    padding: 0.24rem;
    background: #fff;
  }
  .header-box {
    height: 3.8rem;
    background: url("~@/assets/img/hdetails/bg.png") no-repeat center;
    background-size: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 0.28rem;
    box-sizing: border-box;
    .logo {
      position: absolute;
      top: 0.24rem;
      left: 0.24rem;
      width: 1.5rem;
    }
    .avt {
      width: 0.8rem;
    }
    p {
      padding-top: 0.12rem;
      font-size: 0.28rem;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #835e1a;
      line-height: 0.38rem;
    }
    h3 {
      font-size: 0.28rem;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #835e1a;
      line-height: 0.38rem;
      padding-top: 0.1rem;
    }
    .tel-css {
      padding-top: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.32rem;
      font-family: Alibaba PuHuiTi 2-95 ExtraBold, Alibaba PuHuiTi 20;
      font-weight: 800;
      color: #835e1a;
      line-height: 0.46rem;
      img {
        padding-left: 0.08rem;
        width: 0.32rem;
      }
    }
    .h-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.22rem;
      width: 2rem;
      height: 0.6rem;
      background: #ff9d20;
      font-size: 0.3rem;
      font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
      font-weight: 400;
      color: #ffffff;
      border-radius: 0.3rem;
    }
  }
  .content-box {
    padding-top: 0.6rem;
    .c-title {
      font-size: 0.42rem;
      font-family: Alibaba PuHuiTi 2-75 SemiBold, Alibaba PuHuiTi 20;
      font-weight: 600;
      color: #333333;
      line-height: 0.52rem;
    }
    .c-info {
      padding-top: 0.24rem;
      .info-f {
        font-size: 0.28rem;
        font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
        font-weight: 400;
        color: #999999;
        line-height: 0.4rem;
      }
      .info-b {
        display: flex;
        align-items: center;
        padding-top: 0.12rem;
        p {
          display: flex;
          align-items: center;
          font-size: 0.24rem;
          font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
          font-weight: 400;
          color: #999999;
          line-height: 0.38rem;
          img {
            width: 0.18rem;
            height: 0.18rem;
            transform: translateY(-0.02rem);
            margin-right: 0.04rem;
          }
        }
        .see-img {
          width: 0.26rem;
          margin-left: 0.56rem;
        }
        .number-css {
          font-size: 0.24rem;
          font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
          font-weight: 400;
          color: #999999;
          line-height: 0.38rem;
          padding-left: 0.04rem;
        }
      }
    }
    .html-wrap ::v-deep {
      padding-top: 0.3rem;
      img {
        display: block;
        width: 100%;
      }
    }
  }
  .btm-box {
    padding: 0.24rem;
    .list-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.36rem;
      padding-top: 0.32rem;
      .title-l {
        @include flex();
        img {
          width: 0.42rem;
          height: 0.28rem;
        }
        h3 {
          padding-left: 0.12rem;
          font-size: 0.42rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 0.42rem;
        }
      }
      .title-r {
        @include flex();
        h5 {
          font-size: 0.28rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 0.4rem;
          padding-right: 0.08rem;
        }
        img {
          width: 0.12rem;
          height: 0.24rem;
        }
      }
    }
    .zx-item {
      padding: 0.2rem;
      background: #fff;
      border-radius: 0.12rem;
      margin-bottom: 0.2rem;
      padding-bottom: 0;
      display: flex;
      justify-content: space-between;
      .zx-left {
        .zx-title {
          font-size: 0.32rem;
          font-family: Alibaba PuHuiTi 2-65 Medium, Alibaba PuHuiTi 20;
          font-weight: 500;
          color: #333333;
          line-height: 0.38rem;
          height: 0.76rem;
        }
        .zx-info {
          display: flex;
          align-items: center;
          padding-top: 0.12rem;
          p {
            display: flex;
            align-items: center;
            font-size: 0.24rem;
            font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
            font-weight: 400;
            color: #999999;
            line-height: 0.38rem;
            img {
              width: 0.18rem;
              height: 0.18rem;
              transform: translateY(-0.02rem);
              margin-right: 0.04rem;
            }
          }
          .see-img {
            width: 0.26rem;
            margin-left: 0.56rem;
          }
          .number-css {
            font-size: 0.24rem;
            font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
            font-weight: 400;
            color: #999999;
            line-height: 0.38rem;
            padding-left: 0.04rem;
          }
        }
        .zx-tag {
          display: flex;
          flex-wrap: wrap;
          padding-top: 0.12rem;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.2rem;
            height: 0.4rem;
            border-radius: 0.08rem;
            border: 1px solid #ffb251;
            font-size: 0.24rem;
            font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
            font-weight: 400;
            color: #ff9d20;
            line-height: 1;
            margin-right: 0.12rem;
            margin-bottom: 0.12rem;
          }
        }
      }
      .zx-right {
        width: 2.54rem;
        height: 1.78rem;
        padding-left: 0.2rem;
        flex: none;
        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 0.1rem;
        }
      }
    }
    .more-btn {
      font-size: 0.28rem;
      font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
      font-weight: 400;
      color: #999999;
      line-height: 0.38rem;
      text-align: center;
      padding: 0.4rem 0;
    }
  }
}
.fixed-box {
  position: fixed;
  left: 50%;
  width: 7.5rem;
  // height: 1.36rem;
  bottom: 0;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0.24rem 0.32rem;
  box-shadow: 0px -0.02rem 0.04rem 0px rgba(0, 0, 0, 0.07);
  background: #fff;
  padding-bottom: calc(0.32rem + env(safe-area-inset-bottom));
  z-index: 9;
  .home-btn-css {
    flex: none;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    padding-right: 0.2rem;
    img {
      display: block;
      width: 0.42rem;
      height: 0.42rem;
    }
    span {
      font-size: 0.24rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff9d20;
      line-height: 0.34rem;
      padding-top: 0.06rem;
    }
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 0;
    flex: 1;
  }
  .fiexd-btn {
    width: 2.94rem;
    height: 0.8rem;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child {
      border: 1px solid #951e15;
      img {
        width: 0.44rem;
        height: 0.4rem;
      }
      .btn-item {
        color: #951e15;
        padding-left: 0.12rem;
        p {
          font-size: 0.32rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          line-height: 0.32rem;
        }
        span {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 0.28rem;
        }
      }
    }
    &:last-child {
      font-size: 0.32rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      background: #ff9d20;
    }
  }
}
.kf-box {
  position: fixed;
  right: 0;
  position: fixed;
  top: 45vh;
  width: 1.46rem;
  height: 1.56rem;
  background: url("~@/assets/img/homerec/kf-icon.png") no-repeat center top;
  background-size: 1.46rem 1.56rem;
  padding-top: 1rem;
  box-sizing: border-box;
  transform: translateY(-50%);
  .kf-btn {
    width: 1.12rem;
    height: 0.4rem;
    margin: 0 auto;
    background: #ff9d20;
    box-shadow: 0px 0.04rem 0.16rem 0px rgba(172, 118, 51, 0.35);
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.22rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.34rem;
  }
}
.form-wrap {
  padding: 0.5rem;
}
</style>
